// This page will be replaced by createRedirect() under gatsby-node,
// but adding the code removes the warning of missing 404 page and
// fixes local tests for /notFound assertions
import { useEffect } from 'react';

function NotFound404() {
  useEffect(() => {
    window.location.assign(process.env.HDC_NOT_FOUND_REDIRECT_URL);
  }, []);

  return null;
}

export default NotFound404
